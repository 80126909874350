
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {ROUTE_HOME} from "@/router/routes";
import PaymentRepository from "@/api/repositories/PaymentRepository";
import OnBoardingProgress from "@/models/OnBoardingProgress";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import {APPLICATION_STORE_NAME, ApplicationStoreActions, ApplicationStoreGetters} from "@/store/application.store";
import Company from "@/models/Company";
import {namespace} from "vuex-class";
import {AUTH_STORE_NAME, AuthStoreGetters} from "@/store/auth.store";
import Restaurateur from "@/models/Restaurateur";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);
const AuthStore = namespace(AUTH_STORE_NAME);

@Component
export default class PaypalApprovalView extends Vue {
  @ApplicationStore.Action(ApplicationStoreActions.GET_CURRENT_COMPANY)
  private getCompanyById!: (id: string) => Promise<Company>;

  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private user?: Restaurateur;

  /**
   * Reset Token fetched by the URL Query
   * @private
   */
  private token?: string;

  /**
   * flag that indicates that an error occurred while connecting
   * @private
   */
  private hasError: boolean = false;

  /**
   * Loading State Bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * flag that indicates if the connection to PayPal was successful
   * @private
   */
  private succeeded: boolean = false;

  /**
   * tries to connect the user to PayPal when the user enters the screen
   * @private
   */
  private async created(): Promise<void> {
    const agent = navigator.userAgent;
    if(agent === 'merways-app') {
      return;
    }

    await this.connectPaypal();
  }

  /**
   * tries to connect to paypal
   * @private
   */
  private async connectPaypal(): Promise<void> {
    try {
      this.token = this.$route.query.code as string ?? undefined;
      if(!this.token) {
        this.hasError = true;
        return;
      }

      this.hasError = false;
      this.isLoading = true;
      await PaymentRepository.connectPaypal(this.token);
      this.isLoading = false;
      this.succeeded = true;

      await setTimeout(() => {/* waiting here for two seconds to show the success and loading screen
       properly, otherwise is feels rushed when the screens and states are flowing by */}, 2000);

      // updates the users onBoarding progress, gets the company by the users company id, gets the current progress
      // of the user and updates the progress to set the 'paymentAdded' flag to true
      const id = this.user?.company?.id;
      if(id) {
        const company = await this.getCompanyById(id!);
        const progress = OnBoardingProgress.parseFromObject({
          ...company.onboardingProgress,
          paymentAdded: true
        });

        await CompanyRepository.updateCompanyProgress(company.id!, progress);
      }

      this.routeToApplication();
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PAY_PAL_CONNECTED.SUCCESS');
    } catch(e) {
      console.log(e);
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * goes back to the home screen
   */
  public routeToApplication(): void {
    this.$router.replace({name: ROUTE_HOME});
  }
}
