import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

export default class PaymentRepository {
    private static baseUrl: string = 'api/payments/company';

    /**
     * gets the payments of a company
     * @param companyId
     */
    public static getPaymentMethods(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}/paymentMethods`);
    }

    /**
     * creates setup intent (e.g. used for connecting paypal and stripe etc.)
     * @param provider
     * @param companyId
     */
    public static setupIntent(provider: string, companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}/setupIntent?provider=${provider}`);
    }

    /**
     * adds paypal as payment method for the company
     * @param nonce
     * @param companyId
     */
    public static addPaypalAsPaymentMethod(nonce: string, companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`${this.baseUrl}/${companyId}/paymentMethods/braintree`, {
            'paymentMethodNonce': nonce
        });
    }

    /**
     * tries to delete method from company
     * @param paymentMethodId
     * @param companyId
     */
    public static deletePaymentMethod(paymentMethodId: string, companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`${this.baseUrl}/${companyId}/paymentMethods/${paymentMethodId}`);
    }

    /**
     * sets method as company default
     * @param paymentMethodId
     * @param companyId
     */
    public static setMethodAsDefault(paymentMethodId: string, companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`${this.baseUrl}/${companyId}/paymentMethod/setDefault`, {
            paymentMethodId
        });
    }

    /**
     * sets method as company default
     */
    public static getPaypalAuthentication(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`api/payments/paymentMethods/paypal`);
    }

    /**
     * sets method as company default
     */
    public static connectPaypal(code: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`api/payments/paymentMethods/paypal/connect`, {
            'authorizationCode': code
        });
    }
}
